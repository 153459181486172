import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
      font-family: 'Lora', serif;
      background-color: ${({ theme }) => theme.colors.whitestWhite};
      color: ${({ theme }) => theme.colors.blackestBlack};
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    h1 {
      font-family: 'Metal Mania', cursive;
      color: ${({ theme }) => theme.colors.blackestBlack};
    }
  `;
