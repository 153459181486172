import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<{ color?: string; textColor?: string }>`
  width: 90%;
  height: 4rem;
  max-width: 500px;
  margin-top: 3rem;
  padding: 0.5rem;
  font-size: 2rem;
  border: none;
  border-radius: 4px;
  background-color: ${({ color, theme }) => color || theme.colors.whitestWhite};
  color: ${({ textColor, theme }) => textColor || theme.colors.blackestBlack};
  cursor: pointer;
  font-family: 'Metal Mania', cursive;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bloodRed};
    color: ${({theme}) => theme.colors.whitestWhite};
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  color?: string;
  textColor?: string;
}

export const Button = ({ color, textColor, children, ...props }: ButtonProps) => {
  return (
    <StyledButton color={color} textColor={textColor} {...props}>
      {children}
    </StyledButton>
  );
};
