import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { Typography, FormComponent, HighlightedText, LanguageSwitch, SlideUpAnimation } from './shared/components';
import './shared/translation';
import { Language } from './shared/translation';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from './shared/constants';

const Container = styled.div(() => ({
  display: 'flex',
  height: '200vh',
  width: '100vw',
  overflow: 'hidden',
  flexDirection: 'column', // Ensure the default layout for mobile
  backgroundImage: "url('/assets/mob_back.png')", // Mobile background
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overscrollBehavior: 'contain', // Disable bounciness on mobile

  '@media (min-width: 768px)': {
    flexDirection: 'row', // Change layout to row for desktop
    backgroundImage: "url('/assets/desk_back.png')", // Desktop background
    height: '100vh',
  },
}));

const Section = styled.div<{ $isPromo: boolean }>(({ $isPromo }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  top: $isPromo ? 0 : '100%',
  transition: 'transform 0.5s ease, top 0.5s ease', // Smooth transition
  willChange: 'transform', // Hint for smoother transitions
  overscrollBehavior: 'contain', // Disable bounciness on mobile

  '@media (min-width: 768px)': {
    position: 'relative',
    top: 'auto',
    height: '100%',
    width: '50%',
    transform: 'none',
  },
}));

const PromoSection = styled(Section)(({ $isPromo, theme }) => ({
  transform: $isPromo ? 'translateY(0)' : 'translateY(-100%)',

  '@media (min-width: 768px)': {
    transform: 'none',
    border: `1px solid ${theme.colors.whitestWhite}`,
  },
}));

const FormSection = styled(Section)(({ $isPromo }) => ({
  transform: $isPromo ? 'translateY(100%)' : 'translateY(0)',

  '@media (min-width: 768px)': {
    transform: 'none',
  },
}));

const SlideUpContainer = styled.div(() => ({
  position: 'absolute',
  bottom: '60px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  overscrollBehavior: 'none', // Disable bounciness on mobile
}));

export const App = () => {
  const { t, i18n } = useTranslation();
  const [isPromo, setIsPromo] = useState(true);

  const changeLanguage = (lng: Language) => {
    i18n.changeLanguage(lng);
  };

  const currentLanguage = i18n.language as Language;

  const handleSwipeUp = () => {
    if (isPromo) {
      setIsPromo(false);
    }
  };

  const handleSwipeDown = () => {
    if (!isPromo) {
      setIsPromo(true);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: handleSwipeUp,
    onSwipedDown: handleSwipeDown,
    trackTouch: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LanguageSwitch 
        currentLanguage={currentLanguage} 
        onClick={changeLanguage} 
      />
      <Container {...swipeHandlers}>
        <PromoSection $isPromo={isPromo}>
          <div>
            <Typography 
              variant='h1' 
              style={{ 
                fontSize: currentLanguage === 'ru' ? '3rem' : "5rem", 
                textShadow: "2px 2px 4px rgba(0, 0, 0, 1)"
              }}
            >
              {t('promoTextPre')}
              <HighlightedText>{t('promoTextHighlight')}</HighlightedText>
              {t('promoTextPost')}
            </Typography>
            <SlideUpContainer>
              <SlideUpAnimation />
            </SlideUpContainer>
          </div>
        </PromoSection>
        <FormSection $isPromo={isPromo}>
          <FormComponent />
        </FormSection>
      </Container>
    </ThemeProvider>
  );
};





