import {CSSProperties} from 'react'
import styled from 'styled-components';
import { Language } from '../translation';

const LanguageSwitcher = styled.div(() => ({
  position: 'absolute',
  top: '20px',
  left: '20px',
  display: 'flex',
  alignItems: 'center',
  zIndex: 10, /* Ensure it stays on top */
}));

interface LanguageButtonProps {
  $active: boolean;
  customStyles?: CSSProperties
}

const LanguageButton = styled.button<LanguageButtonProps>(({ $active, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0.5rem',
  padding: '0.5rem 1rem',
  fontSize: '1rem',
  cursor: 'pointer',
  border: `0.2px solid ${theme.colors.whitestWhite}`,
  borderRadius: '4px',
  background: $active ? theme.colors.blackestBlack : theme.colors.whitestWhite,
  color: $active ? theme.colors.whitestWhite : theme.colors.blackestBlack,
  transition: 'background 0.3s, color 0.3s',

  '&:hover': {
    background: theme.colors.bloodRed,
    color: theme.colors.whitestWhite,
  },

  img: {
    marginRight: '0.5rem',
    width: '2rem',
    height: '2rem',
    objectFit: 'fill',
  },
}));

const BorderLanguageButton = styled(LanguageButton)(({theme}) => ({
  img: {
    border: `0.2px solid ${theme.colors.blackestBlack}`,
  },
}))


interface Props {
    currentLanguage: Language
    onClick: (lng: Language) => void
}

export const LanguageSwitch = ({currentLanguage, onClick}: Props) => {
    return (
        <LanguageSwitcher>
            <LanguageButton
                $active={currentLanguage === 'en'}
                onClick={() => onClick('en')}
            >
            <img src="/assets/en.png" alt="English" /> EN
            </LanguageButton>
            <BorderLanguageButton
                $active={currentLanguage === 'ru'}
                onClick={() => onClick('ru')}
            >
              <img src="/assets/ru.png" alt="Russian" /> RU
            </BorderLanguageButton>
      </LanguageSwitcher>
    )
}