import React, { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface TypographyProps {
  variant: 'h1' | 'p';
  children: ReactNode;
  style?: CSSProperties;
}

const variants = {
  h1: styled.h1<{ $isRussian: boolean }>`
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.whitestWhite};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: ${({ $isRussian }) => ($isRussian ? 'Spectral SC' : 'Metal Mania')}, sans-serif;
    
    span {
      color: ${({ theme }) => theme.colors.bloodRed};
    }

    @media (min-width: 768px) {
      font-size: 3rem;
      width: 50%;
    }
  `,
  p: styled.p<{ $isRussian: boolean }>`
    font-size: 1rem;
    margin: 0.5rem 0;
    font-family: ${({ $isRussian }) => ($isRussian ? 'Spectral SC' : 'Lora')}, serif;
    color: ${({ theme }) => theme.colors.whitestWhite};
    text-align: center; 
    width: 100%; 
  `,
};

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.bloodRed};
`;

export const Typography = ({ variant, children, style }: TypographyProps) => {
  const { i18n } = useTranslation();
  const isRussian = i18n.language === 'ru';

  const Component = variants[variant] || variants.p;

  return <Component $isRussian={isRussian} style={style}>{children}</Component>;
};





