import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const InputWrapper = styled.div(() => ({
  margin: '1rem 0',
  width: '100%',
}));

const Label = styled.label(({ theme }) => ({
  display: 'flex',
  marginBottom: '0.5rem',
  color: theme.colors.whitestWhite,
  marginLeft: '1rem',
  marginRight: '0.2rem',
}));

const Input = styled.input(({ theme }) => ({
  width: '90%',
  maxWidth: '500px',
  marginBottom: '1rem',
  padding: '0.5rem',
  fontSize: '1rem',
  border: `1px solid ${theme.colors.whitestWhite}`,
  borderRadius: '4px',
}));

interface Props {
  name: string;
  label: string;
  required: boolean;
}

export const InputComponent = ({ name, label, required }: Props) => {
  const { register } = useFormContext();

  return (
    <InputWrapper>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Label htmlFor={name}>{label}</Label>
        {required && <span style={{ color: 'red' }}>*</span>}
      </div>
      <Input id={name} {...register(name)} />
    </InputWrapper>
  );
};

