import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InputComponent } from './input';
import {Button} from './button';
import axios from 'axios';

interface FormValues {
  name: string;
  phoneNumber: string;
}

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
const BackdropImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/assets/dobby.png') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  z-index: 2;
`;

const Form = styled.form`
    width: 100%;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 3;
    align-self: center;
`;

export const FormComponent = () => {
  const methods = useForm({
    defaultValues: {
      name: '',
      phoneNumber: '',
    },
  });
  const { t } = useTranslation();

  const onSubmit = async (data: FormValues) => {
    try {
      await axios.post('https://formspree.io/f/myzgywke', data);
      alert('Form submitted successfully');
    } catch (error) {
      alert('Failed to submit form');
    }
  };

  return (
    <FormProvider {...methods}>
      <Backdrop />
      <BackdropImage />
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputComponent name="name" label={t('name')} required/>
        <InputComponent name="phoneNumber" label={t('phone')} required/>
        <Button type="submit">{t('submit')}</Button>
      </Form>
    </FormProvider>
  );
};


