import React from 'react';
import styled, { CSSProperties, css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from './typography';

interface SlideUpAnimationProps {
  $size?: string;
}

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const Container = styled.div.attrs<SlideUpAnimationProps>(({ $size }) => ({
  style: {
    width: $size,
    height: $size,
  },
}))<SlideUpAnimationProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.whitestWhite,
  textAlign: 'center',
  width: '100%',
  '@media (min-width: 768px)': {
    display: 'none',
  }
}));



const Arrow = styled.div(({ theme }) => css`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${theme.colors.whitestWhite};
  margin: 5px;
  animation: ${bounce} 2s infinite;
`);

export const SlideUpAnimation = ({ $size = '4rem' }: SlideUpAnimationProps) => {
  const { t, i18n } = useTranslation();

  const isRussian = i18n.language === 'ru'

  // Extract the numeric value from the size prop
  const numericSize = parseFloat($size);
  // Calculate font size in rem based on the size prop
  const fontSize = `${numericSize / (isRussian ? 3 : 2)}rem`;

  const textStyle: CSSProperties = {
    fontSize,
    marginTop: '10px',
    color: 'white',
    textAlign: 'center',
  };

  return (
    <Container $size={$size}>
      <Arrow />
      <Arrow style={{ animationDelay: '0.2s' }} />
      <Arrow style={{ animationDelay: '0.4s' }} />
      <Typography variant="p" style={textStyle}>
        {t('slideUp')}
      </Typography>
    </Container>
  );
};






